import React, { Component } from 'react'
import { Link } from 'gatsby'
import { SingleCaeStudyStyle } from './SingleCaeStudy.styles'
import Image from 'components/ImgOptimized'
import { isWindowDefined } from 'config/utils'

class SingleCaseStudy extends Component {
  render() {
    return (
      <SingleCaeStudyStyle
        className={`case-study-single ${this.props.caseStudiesClassName || ''}`}
      >
        <a
          className="case-card-link"
          href={`${isWindowDefined ? window.location.origin : ''}${
            this.props.caseURL
          }`}
        >
          <div className="case-study-bg" />
          <div className="case-study-wrap">
            <div className="content-block">
              <h3>{this.props.caseStudiesTitle}</h3>
              <p>{this.props.caseStudiesParaGraph}</p>
              <span className="line" />
              <div className="btn-read">Read more</div>
            </div>
            <div className="img-block">
              {this.props.fluid && (
                <Image
                  fluid={this.props.fluid}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 430,
                  }}
                />
              )}
            </div>
          </div>
        </a>
      </SingleCaeStudyStyle>
    )
  }
}

export default SingleCaseStudy
