import styled from 'styled-components'
import { xl, lg, md, sm, themeColor, xll, sh } from 'config/variables'

export const CasestudySliderSec = styled.section`
  .heading-wrapper {
    max-width: 682px;
  }
  .case-study-slider {
    margin-top: 50px;
    padding-bottom: 50px;
    .slick-list{
      .slick-track{
        display: flex;
        .slick-slide {
          display: flex;
          height: auto;
          opacity: 0.5;
          padding: 0 30px;
          transition: all 0.5s ease;
          ${xl(`
            padding: 0 20px;
          `)}
          ${lg(`
            padding: 0 20px;
          `)}
          ${md(`
            padding: 0 15px;
          `)}
          ${sm(`
            padding: 0 12px;
            opacity: 1;
          `)}
          &.slick-current {
            opacity: 1;
          }
          > div{
            height: 100%;
            .case-study-single {
              width: 1066px;
              margin-bottom: 0;
              height: 100%;
              ${xl(`
                width: 864px;
              `)}
              ${lg(`
                width: 608px;
              `)}
              ${md(`
                width: 320px;
              `)}
              ${sm(`
                width: 95%;
              `)}
              a {
                height: 100%;
                .case-study-wrap {
                  height: 100%;
                  .content-block {
                    ${xl(`
                      padding: 38px 30px 50px 50px;
                    `)}
                    ${lg(`
                      padding: 45px 57px 20px 66px;
                    `)}
                    ${md(`
                      padding: 20px 30px 40px 40px;
                    `)}
                    ${sm(`
                      padding: 14px 23px 15px 36px;
                    `)}
                  }
                }
              }
            }
          }
        }
      }
    }
    .slick-arrow {
      width: 100px;
      position: absolute;
      padding: 0;
      top: 50%;
      z-index: 9;
      height: 100px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06),
        0 3px 5px -1px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin-top: -50px;
      border-radius: 50%;
      transition: all 0.3s ease;
      outline: none;
      opacity: 1;
      border: 0;
      cursor: pointer;
      ${sh(`
        width: 80px;
        height: 80px;
        margin-top: -40px;
      `)}
      ${md(`
        width: 50px;
        height: 50px;
        margin-top: -25px;
      `)}
      ${md(`
        width: 40px;
        height: 40px;
        margin-top: -20px;
      `)}
      svg {
        width: auto;
        stroke: initial;
        ${sh(`
          width: 25px;
        `)}
        ${md(`
          width: 20px;
          height: auto;
        `)}
        ${md(`
          width: 15px;
          height: auto;
        `)}
      }
      &:hover {
        opacity: 0.8;
      }
      &.slick-prev {
        left: calc((100vw - 1140px) / 2 - 100px);
        ${xll(`
          left: 20px;
        `)}
        ${xl(`
          left: 20px;
        `)}
        ${lg(`
          left: 30px;
        `)}
        ${sm(`
          left: 8px;
        `)}
      }
      &.slick-next {
        right: calc((100vw - 1140px) / 2 - 100px);
        ${xll(`
          right: 20px;
        `)}
        ${xl(`
          right: 20px;
        `)}
        ${lg(`
          right: 30px;
        `)}
        ${sm(`
          right: 8px;
        `)}
      }
    }
    .slick-dots{
      position: absolute;
      left: 0;
      right: 0;
      bottom: -60px;
      display: flex !important;
      justify-content: center;
      ${sm(`
        bottom: -50px;
      `)}
      li{
        list-style: none;
        margin: 0 8px;
        ${sm(`
          margin: 0 5px;
        `)}
        button{
          border: 0;
          cursor: pointer;
          font-size: 0;
          width: 24px;
          height: 4px;
          background: ${themeColor};
          border-radius: 2px;
          padding: 0;
          display: block-size;
          opacity: 0.2;
          transition: all 0.3s ease;      
        }
        &.slick-active{
          button{
            opacity: 1;
          }
        }
      }
    }
  }
`
