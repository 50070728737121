import styled from 'styled-components'
import { themeColor, white, sm, sh, md } from 'config/variables'
import { images } from 'config/images'

export const GetInTouchFormStyle = styled.div`
  width: 100%;
  max-width: 430px;
  padding: 31px 24px 36px;
  border-radius: 3px 3px 0 0;
  background-color: #fff;
  ${sh(`
    padding: 28px 21px 31px;
    max-width: 350px;
  `)}
  ${sm(`
    padding: 20px;
  `)}
  form {
    .input-field-row {
      display: flex;
      margin-bottom: 16px;
      justify-content: space-between;
      position: relative;
      .form-field-blk {
        position: relative;
        width: 100%;
        &.column {
          width: 48%;
        }
        .label-input-field {
          width: 100%;
          height: 55px;
          max-width: 100%;
          margin-bottom: 0px;
          padding: 16px 20px 17px 22px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          background-color: #fff;
          background-image: linear-gradient(180deg, transparent, transparent);
          transition: all 300ms ease;
          color: #3d3d3d;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.17px;
          appearance: none;
          outline: none;
          ${sh(`
            height: 50px;
            padding: 14px 18px 15px 20px;
          `)}
          ${sm(`
            padding: 11px 15px 6px 16px;
            height: 40px;
            font-size: 14px;
          `)}
          & + label {
            pointer-events: none;
          }
          &:focus {
            border-color: #29ded5;
          }
          &:focus + label {
            top: -6px;
            font-size: 11px;
            line-height: 12px;
            padding: 0 2px;
            background: #fff;
            z-index: 9;
          }
          &.fill + label {
            top: -6px;
            font-size: 11px;
            line-height: 12px;
            padding: 0 2px;
            background: #fff;
            z-index: 9;
          }
        }
        &.textarea-row {
          padding: 16px 20px 17px 22px;
          padding-bottom: 60px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          .label-input-field {
            &.textarea {
              padding: 0;
              height: 60px;
              border: 0;
              resize: none;
            }
          }
        }
        label {
          position: absolute;
          left: 22px;
          top: 16px;
          margin-bottom: 0px;
          transition: all 300ms ease;
          color: #b4b4b4;
          font-size: 16px;
          line-height: 22px;
          font-weight: 400;
          letter-spacing: -0.17px;
          cursor: text;
          ${sh(`
            left: 18px;
            top: 13px;
            font-size: 15px;
            line-height: 21px;
          `)}
          ${sm(`
            left: 16px;
            top: 9px;
            font-size: 14px;
          `)}
        }
      }
      .input-file-row {
        width: calc(100% - 32px);
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        margin: 0 auto;
        .error-msg {
          color: #ef5366;
          padding-top: 5px;
          display: block;
        }
        label {
          display: flex;
          padding: 6px 20px;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          border: 1px dashed rgba(0, 0, 0, 0.2);
          background: rgba(229, 229, 229, 0.2);
          border-radius: 5px;
          cursor: pointer;
          ${sm(`
              flex-wrap: wrap;
              justify-content: center;
            `)}
          &.form-error {
            border-color: #ef5366;
          }
          .file-content {
            display: flex;
            align-items: center;
            position: relative;
            margin: 0 auto;
            ${sm(`
                margin-bottom: 15px;
              `)}
            .icon {
              margin-right: 8px;
              width: 17px;
              img {
                display: block;
              }
            }
            .content-label {
              font-size: 14px;
              letter-spacing: -0.15px;
              line-height: 22px;
              color: rgba(61, 61, 61, 0.5);
              ${md(`
                  font-size: 15px;
                  line-height: 21px;
                `)};
            }
          }
          .row-wrap {
            display: flex;
            align-items: center;
            .btn-close {
              width: 15px;
              height: 15px;
              margin-right: 15px;
              position: relative;
              z-index: 9;
              &:after {
                content: '';
                width: 100%;
                height: 2px;
                background: #8f999e;
                position: absolute;
                top: 7.5px;
                left: 0;
                transform: rotate(45deg);
              }
              &:before {
                content: '';
                width: 100%;
                height: 2px;
                background: #8f999e;
                position: absolute;
                top: 7.5px;
                left: 0;
                transform: rotate(-45deg);
              }
            }
          }
        }
        .file-field {
          height: 100%;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }
    }
    .checkbox-row {
      padding-top: 15px;
      position: relative;
      label {
        position: relative;
        padding-left: 33px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.15px;
        transition: all 0.3s ease;
        width: 100%;
        display: block;
        cursor: pointer;
        br {
          ${sm(`
            display: none;
          `)}
        }
        &:after {
          content: '';
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0px;
          left: 0px;
          border: 3px solid #ef5366;
          background-color: transparent;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          transition: all 0.3s ease;
          border-radius: 4px;
        }
      }
      input[type='checkbox'] {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
        &:checked + label {
          &:after {
            background-image: url(${images.iconTickWhite});
            background-color: #ef5366;
          }
        }
      }
    }
    .submit-row {
      margin-top: 19px;
      border-radius: 4px;
      width: 100%;
      background-color: ${themeColor};
      box-shadow: 0 0 7px -2px rgba(0, 0, 0, 0.1);
      transition: all 0.15s ease-in-out;
      border: 1px solid ${themeColor};
      position: relative;
      overflow: hidden;
      z-index: 1;
      text-align: center;
      line-height: 21px;
      cursor: pointer;
      ${sm(`
        margin-top: 10px;
      `)}
      .btn-submit {
        width: 100%;
        background: transparent;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        transition: all 0.15s ease-in-out;
        color: ${white};
        border: 0;
        padding: 0;
        position: relative;
        cursor: pointer;
        padding: 14px 15px 14px;
        ${sm(`
          font-size: 14px;
        `)}
      }
      &:hover {
        .btn-submit {
          color: ${themeColor};
        }
      }
      transition: all 0.15s ease;
      transition: color 0.15s ease-in-out;
      display: inline-block;
      &:after,
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: ${white};
        z-index: -1;
      }
      &:before {
        transform: translateY(-100%);
        transition: all 15ms ease-in-out;
      }
      &:after {
        transform: translateY(100%);
        transition: all 0.2s ease-in-out;
      }
      &:hover:before {
        transform: translateY(0);
        transition: all 0.2s ease-in-out;
      }
      &:hover:after {
        transform: translateY(0);
        transition: all 0ms ease-in-out;
        transition-delay: 0.2s;
      }
    }
    .bottomAlert {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 19px;
    }
    .bottomSuccessText {
      color: #3d3d3d;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      padding-left: 12px;
      margin-bottom: -6px;
      ${sm(`
          font-size:14px;
      `)}
    }
    .bottomErrorText {
      margin-top: 15px;
      color: #ef5366;
      font-size: 16px;
      font-weight: 500;
    }
  }
`
