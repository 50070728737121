import styled from 'styled-components'
import {
  md,
  sm,
  white,
  bodyFont,
  device,
  primaryColor,
  sh,
} from 'config/variables'

export const SingleServiceStyle = styled.div`
  max-width: 354px;
  width: 100%;
  padding: 21px 24px 23px;
  display: flex;
  align-items: flex-start;
  background-color: ${white};
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06),
    0 3px 5px -1px rgba(0, 0, 0, 0.1);
  transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
  border: 1px solid ${white};
  ${sh(`
    max-width: 310px;
    padding: 18px 21px 20px;
  `)}
  ${md(`
    padding: 20px;
    `)}
  ${sm(`
    max-width: 280px;
    padding: 17px;
    flex-wrap: wrap;
    flex-direction: column;
    `)}
  &.very-light-pink {
    border-color: #fcd9c3;
    &:hover {
      background-color: #fff4ed;
      .service-content-blk {
        h4, h4 a { 
          color: #f78761; 
        }
      }
    }
  }
  
  &.pale {
    border-color: #ead8b2;
    &:hover {
      background-color: #fff4dc;
      .service-content-blk {
        h4, h4 a { 
          color: #edb441;
        }
      }
    }
  }
  &.pale-grey {
    border-color: #c4d1ea;
    &:hover {
      background-color: #eaf1ff;
      .service-content-blk {
        h4, h4 a { 
          color: #698ede;
        }
      }
    }
  }
  &.sky-blue {
    border-color: #b8dffd;
    &:hover {
      background-color: #ddf0ff;
      .service-content-blk {
        h4, h4 a { 
          color: #65a1d2;
        }
      }
    }
  }
  &.ice-blue {
    border-color: #b5ebe7;
    &:hover {
      background-color: #e3fffd;
      .service-content-blk {
        h4, h4 a { 
          color: #3ebcb6;
        }
      }
    }
  }
  &.ice {
    border-color: #b6e7bd;
    &:hover {
      background-color: #e5ffe9;
      .service-content-blk {
        h4, h4 a {
          color: #5db86b;
        }
      }
    }
  }
  &.pale-lavender {
    border-color: #dfcbee;
    &:hover {
      background-color: #f5e8ff;
      .service-content-blk {
        h4, h4 a {
          color: #9a64c3;
        }
      }
    }
  }
  &.very-light-pink-two {
    border-color: #dcdcdc;
    &:hover {
      background-color: #f1f1f1;
      .service-content-blk {
        h4, h4 a {
          color: #888888;
        }
      }
    }
  }
  
  &.off-white {
    border-color: #e7e4bb;
    &:hover {
      background-color: #fffde6;
      .service-content-blk {
        h4, h4 a {
          color: #a5a271;
        }
      }
    }
  }
  &.very-light-pink-three {
    border-color: #FFDCE2;
    &:hover {
      background-color: rgb(255 220 226 / 25%);
      .service-content-blk {
        h4, h4 a {
          color: #EF5366;
        }
      }
    }
  }
  &.duck-egg-blue {
    border-color: #97c2bf;
    &:hover {
      background-color: #eefcfb;
      .service-content-blk {
        h4, h4 a {
          color: #69a4a0;
        }
      }
    }
  }
  &.beige-four {
    border-color: #f1cfd5;
    &:hover {
      background-color: #FFEAEE;
      .service-content-blk {
        h4, h4 a {
          color: #FFEAEE;
        }
      }
    }
  }
  &.dark-green {
    border-color: #b8eaa3;
    &:hover {
      background-color: #e6f9de;
      .service-content-blk {
        h4, h4 a {
          color: #82c168;
        }
      }
    }
  }
  &.light-green {
    border-color: #add4d1;
    &:hover {
      background-color: #eef9f8;
      .service-content-blk {
        h4, h4 a {
          color: #8ab9b5;
        }
      }
    }
  }
  &:hover {
    box-shadow: 0 11px 15px -3px rgba(0, 0, 0, 0.1),
      0 9px 30px 3px rgba(0, 0, 0, 0.04), 0 20px 30px 3px rgba(0, 0, 0, 0.04);
    transform: translateY(-5px);
    ${device(`
        box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.06), 0 3px 5px -1px rgba(0, 0, 0, 0.1);
        transform: initial;
    `)}
    .service-img-blk {
      transform: translateY(-11px);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px;
      ${device(`
          box-shadow: initial;
          transform: initial;
      `)}
    }
    .service-content-blk {
      h4 {
        transform: translateY(-7px);
        ${device(`
          box-shadow: initial;
          transform: initial;
      `)}
      }
      p {
        transform: translateY(-4px);
        ${device(`
          box-shadow: initial;
          transform: initial;
      `)}
      }
    }
  }
  .service-img-blk {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
    ${sh(`
      width: 43px;
      height: 43px;
      margin-right: 14px;
    `)}
    ${sm(`
      margin-bottom: 15px;
      `)}
  }
  .service-content-blk {
    flex: 1;
    ${sm(`
      width: 100%;
      `)}
    h4 {
      font-size: 18px;
      font-family: ${bodyFont};
      margin-bottom: 7px;
      line-height: 24px;
      transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      ${sh(`
        font-size: 16px;
        line-height: 22px;
      `)}
      ${md(`
        margin-bottom: 10px;
      `)}
      a {
        color: ${primaryColor};
        transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      }
    }
    p {
      font-size: 17px;
      line-height: 23px;
      margin: 0;
      transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
      ${sh(`
        font-size: 15px;
        line-height: 21px;
      `)}
    }
  }
`
