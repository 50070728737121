import React from 'react'
import SingleService from 'components/SingleService/SingleService'
import { PPCServiceStyle } from './PPCService.styles'
import { useSlider } from 'components/hooks/useSlider'

const PPCService = ({ ppcServicedata }) => {
  const { ppcServiceProps } = ppcServicedata
  let selector = 'ppc-service-list-wrap'
  let wrapper = 'ppc-service-list'
  let scroll = 'indicator-container'
  let scrollIndecator = `indicator-inner`

  useSlider({ selector, wrapper, scroll, scrollIndecator, speed: 5 })

  return (
    <PPCServiceStyle>
      <div className="heading-wrapper">
        <h2>{ppcServiceProps.heading}</h2>
      </div>
      <div className={selector}>
        <div className={wrapper}>
          {ppcServiceProps.serviceContents.map((serviceContent, i) => {
            return (
              <div key={i} className="service-single-wrap">
                <SingleService {...serviceContent} />
              </div>
            )
          })}
        </div>
      </div>
      <div className="indicator-container">
        <div className="indicator-inner"></div>
      </div>
    </PPCServiceStyle>
  )
}

export default PPCService
