import React, { useState } from 'react'
import ClientsLogo from 'components/ClientsLogo/ClientsLogo'
// import LightBoxImgVideo from 'components/LightBoxImgVideo/LightBoxImgVideo'
import { BuildAppStyle } from './BuildApplication.styles'
// import { images } from 'config/images'
// import Button from 'components/Button/Button'
import Image from 'components/ImgOptimized'

const BuildApplication = ({ buildApplicationData, posterImage }) => {
  const [state, setState] = useState({ addClass: false })

  const { buildApplicationProps } = buildApplicationData
  // console.log('buildApplicationData', buildApplicationProps.buildVid)

  // const handleClick = () => {
  //   var element = document.getElementById('element')

  //   //console.log(element)
  //   element.removeAttribute('controls')
  //   if (element.requestFullscreen) {
  //     element.requestFullscreen()
  //   } else if (element.webkitRequestFullscreen) {
  //     element.webkitRequestFullscreen()
  //   } else if (element.mozRequestFullScreen) {
  //     element.mozRequestFullScreen()
  //   } else if (element.msRequestFullscreen) {
  //     element.msRequestFullscreen()
  //   }
  // }

  const playPauseClick = () => {
    // var element = document.getElementById('element')
    setState({ addClass: true })
  }

  return (
    <BuildAppStyle className="buildapp-section" id="simform-video">
      <div className="container">
        {buildApplicationProps.buildApplicationHeading && (
          <div className="heading-wrapper">
            <h2 className="h3">
              {buildApplicationProps.buildApplicationHeading}
            </h2>
          </div>
        )}
        {buildApplicationProps.buildAppPara && (
          <div className="heading-para">
            <p>{buildApplicationProps.buildAppPara}</p>
          </div>
        )}
        {buildApplicationProps.headDetails && (
          <div className="heading-details">
            {buildApplicationProps.headDetails.map((details, i) => {
              return (
                <div key={i} className="col-item">
                  {details.Para && <p>{details.Para}</p>}
                  {details.detailsList && (
                    <ul>
                      {details.detailsList.map((item, j) => {
                        return <li key={`${i}${j}`}>{item}</li>
                      })}
                    </ul>
                  )}
                </div>
              )
            })}
          </div>
        )}
        {buildApplicationProps.buildVid &&
          (buildApplicationProps.lightboxImgVid && (
            <div className="app-video-wrapper">
              <div
                className={`app-video-block ${state.addClass ? 'active' : ''}`}
              >
                {/* <div className="full-screen" onClick={handleClick}>
            <img src={images.fullScreen} alt="" />
          </div> */}
                {buildApplicationProps.autoPlay ? (
                  // <video
                  //   id="element"
                  //   autoPlay=""
                  //   muted
                  //   loop
                  //   playsInline
                  //   poster={buildApplicationProps.poster}
                  // >
                  //   <source src={buildApplicationProps.buildVid} type="video/mp4" />
                  // </video>
                  <iframe
                    id="element"
                    src={`https://www.youtube.com/embed/${buildApplicationProps.buildVid}/?autoplay=1&rel=0&showinfo=0&controls=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <>
                    <iframe
                      id="element"
                      src={`${
                        state.addClass
                          ? `https://www.youtube.com/embed/${buildApplicationProps.lightboxImgVid}?autoplay=1&loop=1&rel=0&showinfo=0&controls=0&playlist=${buildApplicationProps.lightboxImgVid}`
                          : ''
                      }`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                    <div className="poster-wrapper">
                      <Image
                        fluid={posterImage}
                        alt={buildApplicationProps.posterAlt}
                        lazyload={{
                          enabled: false,
                        }}
                      />
                      <button
                        className="play-pause-buttton"
                        onClick={playPauseClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M-12-8h36v36h-36z" />
                            <path
                              fill="#FFF"
                              d="M0 2.233v15.534a1.5 1.5 0 0 0 2.305 1.266l12.206-7.768a1.5 1.5 0 0 0 0-2.53L2.305.967A1.5 1.5 0 0 0 0 2.233z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </>
                )}
                {/* <LightBoxImgVideo
            lightboxImgVid={[buildApplicationProps.lightboxImgVid]}
            lightButtonClassName={'play-pause-buttton'}
            buttonText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="20"
                viewBox="0 0 16 20"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M-12-8h36v36h-36z" />
                  <path
                    fill="#FFF"
                    d="M0 2.233v15.534a1.5 1.5 0 0 0 2.305 1.266l12.206-7.768a1.5 1.5 0 0 0 0-2.53L2.305.967A1.5 1.5 0 0 0 0 2.233z"
                  />
                </g>
              </svg>
            }
          /> */}
              </div>
            </div>
          ))}
      </div>
      {buildApplicationData.clientLogoPrpos && (
        <ClientsLogo clientlogodata={buildApplicationData} />
      )}
    </BuildAppStyle>
  )
}

export default BuildApplication
