import React, { memo } from 'react'
import SingleBlogCard from 'components/SingleBlogCard/SingleBlogCard'
import ContactInfoCard from 'components/ContactInfoCard/ContactInfoCard'
import { FeatureResourcesStyle } from './FeatureResources.styles'

const FeatureResources = memo(
  ({ featureresourceData: { featureResourceProps }, data }) => {
    const { CTACard: CTACardProp } = featureResourceProps
    const {
      dvdReplication1: {
        childImageSharp: { fluid: blog1 },
      },
      dvdReplication2: {
        childImageSharp: { fluid: blog2 },
      },
      dvdReplication3: {
        childImageSharp: { fluid: blog3 },
      },
    } = data
    let items = [blog1, blog2, blog3]

    return (
      <FeatureResourcesStyle className="feature-resource-section">
        <div className="container">
          <div className="heading-wrapper">
            <h2 className="h3">{featureResourceProps.heading}</h2>
          </div>
          <div className="feature-listing">
            {featureResourceProps.featureContents.map((featureContent, i) => {
              return (
                <SingleBlogCard key={i} fluid={items[i]} {...featureContent} />
              )
            })}
          </div>
        </div>
        {CTACardProp && <ContactInfoCard {...CTACardProp} />}
      </FeatureResourcesStyle>
    )
  }
)

export default FeatureResources
