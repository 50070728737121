import styled from 'styled-components'
import { md, sm, lg, sh, device } from 'config/variables'

export const PPCServiceStyle = styled.section`
  margin-bottom: 110px;
  overflow: hidden;
  position: relative;
  ${sh(`
    margin-bottom: 95px;
  `)}
  ${lg(`
    margin-bottom: 80px;
  `)}
  ${md(`
    margin-bottom: 40px;
  `)}
  .heading-wrapper {
    ${md(`
      padding: 0 20px;
    `)}
  }
  .indicator-container{
    width: 180px;
    height: 1px;
    background: #dddddd;
    margin: 0 auto;
    position: relative;
    margin-top:40px;
    .indicator-inner{
      width: 40px;
      height: 6px;
      left: 0;
      position: absolute;
      top: -6px;
      background: #3d3d3d;
    }
    ${device(`
      display:none;
    `)}
  }
  .ppc-service-list-wrap {
    position: relative;
    overflow: hidden; 
    ${device(`
      overflow: auto;
    `)}
    .ppc-service-list {
      padding: 40px 0 40px;
      display: flex;
      padding-left: calc((100vw - 1140px)/2);
      ${md(`
        padding: 40px 0;
        `)}
      .service-single {
        height: 100%;
        max-width: 320px;
        min-width: 320px;
        margin: 0 20px;
        position: relative;
        flex-direction: column;
        padding: 30px;
        text-align: left;
        align-items: flex-start;
        ${sh(`
          max-width: 310px;
          padding: 27px;
          margin: 0 18px;
          min-width: 310px;
        `)}
        ${md(`
            padding: 25px;
            margin: 0 15px;
            max-width: 280px;
            min-width: 280px;
        `)}
        ${sm(`
            padding: 20px;
            margin: 0 12px;
            max-width: 260px;
            min-width: 260px;
        `)}
        .service-img-blk {
          width: 100px;
          height: 100px;
          margin: 0;
          margin-bottom: 23px;
          ${sh(`
            width: 80px;
            height: 80px;
          `)}
          img {
            width: 100%;
          }
          ${md(`
              {
              width: 60px;
              height: 60px;
              margin-bottom: 15px;
            }
          `)}
        }
      }
    }
  }
`
