import styled from 'styled-components'
import { xl, lg, md, sm, sh, primaryColor, white } from 'config/variables'

export const ProcessFlowStyle = styled.section`
  .container{
    ${md(`
      padding: 0px;
    `)}
    .heading-wrapper{
      ${md(`
        padding: 0 15px;
      `)}
    }
    .processflow-headig{
      max-width: 840px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      margin-top: 45px;
      padding: 0 20px;
      h4{
        font-size: 30px;
        line-height: 36px;
        letter-spacing: -0.33px;
        color: #222222;
        font-weight: 500;
      }
      p{
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.15px;
        color: ${primaryColor};
      }
    }
    .working-benefit-list {
      padding-top: 40px;
      ${sm(`
        padding-top: 0;
      `)}
      .benefit-single-block {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 129px;
        position: relative;
        ${sh(`
          margin-bottom: 116px;
        `)}
        ${xl(`
          justify-content: space-around;
          margin-bottom: 118px;
        `)}
        ${lg(`
          justify-content: space-between;
          margin-bottom: 76px;
        `)}
        ${md(`
          flex-wrap: wrap;
          justify-content: center;
          background: linear-gradient(to top, #f8f5ff, #fff);
          padding: 40px 20px;
          margin-bottom: 0;
        `)}
        .three-layer-block {
          max-width: 468px;
          height: 472px;
          width: 100%;
          margin: 0 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          ${sh(`
            max-width: 414px;
            height: 418px;
          `)}
          ${xl(`
            margin: 0;
            max-width: 410px;
            height: 413px;
          `)}
          ${lg(`
            margin: 0;
            max-width: 360px;
            height: 362px;
          `)}
          ${md(`
            max-width: 400px;
            height: 403px;
            margin-bottom: 40px;
          `)}
          ${sm(`
            max-width: 280px;
            height: 280px;
            margin-bottom: 30px;
          `)}
          .back-layer {
            position: absolute;
            width: calc(100% - 60px);
            height: calc(100% - 60px);
            top: 0;
            right: 0;
            background: #f3eefd;
            opacity: 0.5;
            z-index: 1;
            ${md(`
              width: calc(100% - 50px);
              height: calc(100% - 50px);
            `)}
          }
          .front-layer {
            position: absolute;
            width: calc(100% - 60px);
            height: calc(100% - 60px);
            bottom: 0;
            left: 0;
            background: #dbd0f4;
            opacity: 0.7;
            z-index: 2;
            ${md(`
              width: calc(100% - 50px);
              height: calc(100% - 50px);
            `)}
          }
          .middle-layer {
            width: calc(100% - 60px);
            height: calc(100% - 60px);
            background: #c6b9e5;
            position: relative;
            z-index: 3;
            display: flex;
            align-items: center;
            ${md(`
              width: calc(100% - 50px);
              height: calc(100% - 50px);
            `)}
            .mob-img{
              display: none;
              ${md(`
                display: block;
              `)}
            }
            .gatsby-image-wrapper{
              width: 100%;
            }
            .form {
              padding: 34px;
              background: transparent;
              ${lg(`
                padding: 20px 19px;
              `)}
              ${md(`
                display: none;
              `)}
              form {
                .input-field-row {
                  ${xl(`
                    margin-bottom: 12px;
                  `)}
                  ${lg(`
                    margin-bottom: 12px;
                  `)}
                  ${sm(`
                    margin-bottom: 8px;
                  `)}
                  &.select-row {
                    display: none;
                  }
                  .form-field-blk {
                    .label-input-field {
                      background: transparent;
                      ${xl(`
                        padding: 8px 15px 8px 15px;
                        height: 50px;
                      `)}
                      ${lg(`
                        padding: 8px 15px 8px 15px;
                        height: 42px;
                        font-size: 14px;
                      `)}
                      ${sm(`
                        padding: 6px 12px 6px 12px;
                        height: 32px;
                      `)}
                      &:focus {
                        border-color: #ff4d63;
                      }
                      &:focus + label {
                        background: #d0f3ec;
                        ${xl(`
                          top: -9px;
                        `)}
                        ${lg(`
                          top: -6px;
                        `)}
                        ${sm(`
                          font-size: 10px;
                        `)}
                      }
                      &.fill + label {
                        background: #d0f3ec;
                        ${xl(`
                          top: -9px;
                        `)}
                        ${lg(`
                          top: -6px;
                        `)}
                        ${sm(`
                          font-size: 10px;
                        `)}
                      }
                      &.textarea {
                        height: 55px;
                        ${lg(`
                          height: 42px;
                        `)}
                      }
                    }
                    label {
                      ${xl(`
                        top: 14px;
                        line-height: 20px;
                        font-size: 15px;
                      `)}
                      ${lg(`
                        left: 15px;
                        top: 13px;
                        line-height: 16px;
                        font-size: 12px;
                      `)}
                      ${sm(`
                        left: 12px;
                        top: 10px;
                        line-height: 14px;
                        font-size: 11px;
                      `)}
                    }
                  }
                }
                .submit-row {
                  margin: 0;
                  ${lg(`
                    padding: 8px 15px 9px;
                  `)}                
                  ${sm(`
                    padding: 4px 12px 6px;
                  `)}
                  .btn-submit {
                    ${lg(`
                      font-size: 14px;
                      line-height: 18px;
                    `)}
                    ${sm(`
                      font-size: 12px;
                      line-height: 16px;
                      letter-spacing: 0;
                    `)}
                  }
                }
              }
            }
          }
        }
        .content-block {
          max-width: 468px;
          width: 100%;
          margin: 0 50px;
          max-height: 472px;
          overflow: hidden;
          ${sh(`
            max-height: 418px;
          `)}
          ${xl(`
            margin: 0;
            max-width: 430px;
            max-height: 413px;
          `)}
          ${lg(`
            margin: 0;
            max-width: 270px;
            max-height: 362px;
          `)}
          ${md(`          
            max-width: 400px;
            max-height: initial;
          `)}
          h3 {
            font-size: 30px;
            line-height: 42px;
            margin-bottom: 20px;
            color: #222222;
            ${sh(`
              font-size: 27px;
              line-height: 39px;
            `)}
            ${lg(`
              font-size: 28px;
              line-height: 34px;
            `)}
            ${sm(`
              font-size: 22px;
              line-height: 30px;
              margin-bottom: 10px;
            `)}
          }
          p {
            font-size: 22px;
            line-height: 30px;
            margin: 0;
            ${sh(`
              font-size: 20px;
              line-height: 28px;
            `)}
            ${lg(`
              font-size: 20px;
              line-height: 28px;
            `)}
            ${sm(`
              font-size: 18px;
              line-height: 28px;
            `)}
            strong {
              font-weight: 500;
            }
          }
          ol, ul{
            margin-left: 22px;
            li{
              font-size: 20px;
              line-height: 28px;
              ${sh(`
                font-size: 18px;
                line-height: 26px;
              `)}
              ${lg(`
                font-size: 18px;
                line-height: 26px;
              `)}
              ${sm(`
                font-size: 16px;
                line-height: 26px;
              `)}
            }
          }
        }
        &:nth-child(2n) {
          flex-direction: row-reverse;
          ${xl(`
            margin-bottom: 126px;
          `)}
          ${lg(`
            margin-bottom: 90px;
          `)}
          ${md(`
            margin-bottom: 0;
          `)}
          .patern-block {
            ${xl(`
              bottom: -142px;
            `)}
            ${lg(`
              bottom: -102px;
            `)}
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.pale {
          ${md(`
            background:  linear-gradient(to top, rgba(255, 234, 218, 0.5), #fff);
          `)}
          .back-layer {
            background: #ffeada;
          }
          .front-layer {
            background: #ffd4b2;
            opacity: 0.5;
          }
          .middle-layer {
            background: #ffe0c8;
          }
        }
        &.duck {
          ${md(`
            background:  linear-gradient(to top, rgba(220, 254, 247, 0.5), #fff);
          `)}
          .back-layer {
            background: #beefe5;
            opacity: 0.3;
          }
          .front-layer {
            background: #d2f6ef;
            opacity: 0.4;
          }
          .middle-layer {
            background: #d0f3ec;
          }
        }
        &.bluegrey {
          ${md(`
            background:  linear-gradient(to top, rgba(215, 239, 255, 0.5), #fff);
            `)}
          .back-layer {
            background: #7cb1d3;
            opacity: 0.4;
          }
          .front-layer {
            background: linear-gradient(224deg, #a6d3ef, #a0cfed);
            opacity: 0.7;
          }
          .middle-layer {
            background: #a6d3ef;
          }
        }
        &.light-lavender {
          ${md(`
            background:  linear-gradient(to top, rgba(244, 230, 255, 0.5), #fff);
            `)}
          .back-layer {
            background: #e3beff;
            opacity: 0.4;
          }
          .front-layer {
            background: linear-gradient(224deg, #f4e6ff, #f2e2ff);
            opacity: 0.7;
          }
          .middle-layer {
            background: #f4e6ff;
          }
        }
        &.pale-lilac-three {
          ${md(`
            background:  linear-gradient(to top, rgba(216, 216, 249, 0.5), #fff);
          `)}
          .back-layer {
            background: #cdcdff;
            opacity: 0.4;
          }
          .front-layer {
            background: #cdcdff;
            opacity: 0.4;
          }
          .middle-layer {
            background: #d8d8f9;
          }
        }
        &.pale-mauve-three {
          ${md(`
            background:  linear-gradient(to top, rgba(249, 214, 247, 0.5), #fff);
          `)}
          .back-layer {
            background: #fdc9fa;
            opacity: 0.55;
          }
          .front-layer {
            background: #fdc9fa;
            opacity: 0.55;
          }
          .middle-layer {
            background: #f9d6f7;
          }
        }
        &.light-sky-blue-two {
          ${md(`
            background:  linear-gradient(to top, rgba(204, 235, 249, 0.5), #fff);
          `)}
          .back-layer {
            background: #b1e2f9;
            opacity: 0.4;
          }
          .front-layer {
            background: #b1e2f9;
            opacity: 0.4;
          }
          .middle-layer {
            background: #ccebf9;
          }
        }
        &.pale-four {
          ${md(`
            background:  linear-gradient(to top, rgba(253, 216, 213, 0.5), #fff);
          `)}
          .back-layer {
            background: #f9bbc0;
            opacity: 0.3;
          }
          .front-layer {
            background: #f9bbc0;
            opacity: 0.3;
          }
          .middle-layer {
            background: #fdd8d5;
          }
        }
        &.light-periwinkle-two {
          ${md(`
            background:  linear-gradient(to top, rgba(253, 216, 213, 0.5), #fff);
          `)}
          .back-layer {
            background: #c9d8fa;
            opacity: 0.4;
          }
          .front-layer {
            background: #c9d8fa;
            opacity: 0.4;
          }
          .middle-layer {
            background: #d2defa;
          }
        }
        &.pale-sky-blue {
          ${md(`
            background:  linear-gradient(to top, rgba(184, 238, 246, 0.5), #fff);
          `)}
          .back-layer {
            background: #b8eef6;
            opacity: 0.5;
          }
          .front-layer {
            background: #b8eef6;
            opacity: 0.5;
          }
          .middle-layer {
            background: #c9f0f6;
          }
        }
        &.pale-sky-blue-two {
          ${md(`
            background:  linear-gradient(to top, rgba(196, 235, 248, 0.5), #fff);
          `)}
          .back-layer {
            background: #c4eef8;
            opacity: 0.4;
          }
          .front-layer {
            background: #c4eef8;
            opacity: 0.4;
          }
          .middle-layer {
            background: #c4ebf8;
          }
        }
        &.pale-lilac-four {
          ${md(`
            background:  linear-gradient(to top, rgba(230, 214, 255, 0.5), #fff);
          `)}
          .back-layer {
            background: #e6d6ff;
            opacity: 0.4;
          }
          .front-layer {
            background: #e6d6ff;
            opacity: 0.4;
          }
          .middle-layer {
            background: #e6d6ff;
          }
        }
        &.pale-aqua {
          ${md(`
            background:  linear-gradient(to top, rgba(206, 246, 219, 0.5), #fff);
          `)}
          .back-layer {
            background: #cef6db;
            opacity: 0.4;
          }
          .front-layer {
            background: #cef6db;
            opacity: 0.4;
          }
          .middle-layer {
            background: #cef6db;
          }
        }
        &.very-light-blue-two {
          ${md(`
            background:  linear-gradient(to top, rgba(223, 231, 255, 0.5), #fff);
          `)}
          .back-layer {
            background: #dfe7ff;
            opacity: 0.5;
          }
          .front-layer {
            background: #dfe7ff;
            opacity: 0.5;
          }
          .middle-layer {
            background: #dfe7ff;
          }
        }
        &.blue-gradient {
          ${md(`
            background:  linear-gradient(to top, rgba(223, 231, 255, 0.5), #fff);
          `)}
          .back-layer {
            background-image: linear-gradient(to top, ${white}, #eef2ff);
            opacity:1;
          }
          .front-layer {
            background-image: linear-gradient(to top, #eef2ff, ${white});
            opacity:1;
          }
          .middle-layer {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            box-shadow: 0 5px 10px 0 rgba(161, 175, 221, 0.09);
            border: solid 1px #e8e9f3;
            background-color: rgba(255, 255, 255, 0.7);
          }
        }
        .patern-block {
          position: absolute;
          bottom: -145px;
          left: 0;
          right: 0;
          margin: 0 auto;
          z-index: 2;
          ${sh(`
            bottom: -138px;
          `)}
          ${xl(`
            bottom: -131px;
          `)}
          ${lg(`
            bottom: -86px;
          `)}
          ${md(`          
            display: none;
          `)}
          img {
            display: block;
            margin: 0 auto;
            ${sh(`
              transform: scale(0.9);
            `)}
            ${xl(`
              max-width: 600px;
            `)}
            ${lg(`
              max-width: 390px;
            `)}
          }
        }
      }
    }
  }
`
