import styled from 'styled-components'
import {
  themeColor,
  white,
  xl,
  lg,
  sm,
  md,
  sh,
  primaryColor,
} from 'config/variables'

export const PPCGetInTouchStyle = styled.section`
  position: relative;
  padding: 0px 0 87px;
  ${sm(`
    padding: 0px 0 45px;
  `)}
  &:after {
    content: '';
    width: 100%;
    height: 140%;
    background: linear-gradient(to top, #eef2ff,#fff);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .getintouch-row {
    display: flex;
    border-radius: 3px;
    ${lg(`
      flex-wrap: wrap;
    `)}
    .form-row {
      flex: 63%;
      background: #fff;
      padding: 22px 48px 38px;
      ${lg(`
        flex: 100%;
      `)}
      ${md(`
        padding: 24px 26px;
      `)}
      ${sm(`
        padding: 14px 16px;
      `)}
      h3 {
        font-size: 24px;
        line-height: 53px;
        letter-spacing: -0.26px;
        margin-bottom: 10px;
      }
    }
    .form-content-row {
      flex: 37%;
      background: #ffe0c8;
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      ${sh(`
        padding: 36px;
      `)}
      ${xl(`
        flex: 40%;
        padding: 40px;
      `)}
      ${lg(`
        flex: 100%;
        padding: 40px;
      `)}
      ${md(`
        order: -1;
      `)}
      ${sm(`
        padding: 40px 26px;        
      `)}      
      .row-content { 
        text-align: center;
        width: 100%;
        ${lg(`
          max-width: 350px;
        `)}
        .person-img{
          max-width: 110px;
          width: 100%;
          display: block;
          margin: 0 auto;
          margin-bottom: 24px;
          img{
            display: block;
          }
        }       
        h4 {
          font-size: 24px;
          line-height: 30px;
          letter-spacing: -0.26px;
          margin-bottom: 0;
          ${sh(`
            font-size: 22px;
            line-height: 28px;
          `)}
          ${sm(`
            font-size: 20px;
            line-height: 26px;
          `)} 
        }
        p {
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 45px;
          margin-top: 10px;
          color: rgba(61, 61, 61, 0.6);
          ${sh(`
            font-size: 17px;
            line-height: 23px;
          `)}            
          ${sm(`
            margin-bottom: 30px;
          `)} 
          ${sm(`
            font-size: 16px;
            line-height: 22px;
          `)} 
        }
        .email{
          font-size: 20px;
          line-height: 27px;
          color: ${themeColor}; 
          display: inline-block;
          transition: all 0.3s ease;
          &:hover{
            color: ${primaryColor};
          }
        }
        .button {
          font-size: 16px;
          line-height: 22px;
          letter-spacing: 1.6px;
          text-align: center;
          color: ${white};
          background: ${themeColor};
          border: 1px solid ${themeColor};
          border-radius: 4px;
          padding: 9px 30px 11px;
          position: relative;
          overflow: hidden;
          transition-delay: 0s, .15s;
          transition: color 0.2s ease-in-out, background-color 0.1s ease 0.02s;
          font-weight: 500;
          text-transform: uppercase;
          display: block;
          ${sh(`
            font-size: 15px;
            line-height: 21px;
            padding: 9px 27px 11px;
          `)}
          ${md(`
            font-size: 18px;
            line-height: 26px;
            padding: 8px 24px 10px;
          `)}
          ${sm(`
            font-size: 14px;
            line-height: 21px;
            padding: 9px 15px 10px;
          `)}
          &:hover {
            color: ${themeColor};
            background-color:${themeColor}; 
            transition-delay: 0s, .15s; 
          }
          span {
            position: relative;
          z-index: 10;
          }
          &:after,
          &:before {
            content: '';
            position: absolute;
            height: 100%;
            width:101%;
            right:0;
            top: 0;
            left: 0;
            transform: translate(0);
            background: ${white};
          }
          &:before {
            transform: translateY(-100%);
            transition: transform 15ms ease-in-out;
          }
          &:after {
            transform: translateY(100%);
            transition: transform 0.2s  ease-in-out;
          }
          &:hover:before {
            transform: translateY(0);
            transition: transform 0.2s ease-in-out;
          }
          &:hover:after {
            transform: translateY(0);
            transition: transform 0ms ease-in-out;
            transition-delay: 0.2s;
          }
        }
      }
    }
  }
`
