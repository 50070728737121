import React, { Component } from 'react'
import { PPCGetInTouchStyle } from './PPCGetInTouch.styles'
import PPCGetInTouchForm from 'components/PPCGetInTouchForm/PPCGetInTouchForm'
import Picture from 'components/Picture/Picture'

class PPCGetInTouch extends Component {
  render() {
    const { ppcGetTouchProps } = this.props.ppcGetTouchData
    return (
      <PPCGetInTouchStyle>
        <div className="container">
          <div className="getintouch-row">
            <div className="form-row">
              <h3>{ppcGetTouchProps.formHeading}</h3>
              <PPCGetInTouchForm />
            </div>
            <div className="form-content-row">
              <div className="row-content">
                <div className="person-img">
                  <Picture
                    height={110}
                    offset={2000}
                    srcSet={ppcGetTouchProps.personImg2x}
                    src={ppcGetTouchProps.personImg}
                    alt=""
                  />
                </div>
                <h4>{ppcGetTouchProps.personName}</h4>
                {ppcGetTouchProps.personEmail && (
                  <a
                    className="email"
                    href={`mailto:${ppcGetTouchProps.personEmail}`}
                  >
                    {ppcGetTouchProps.personEmail}
                  </a>
                )}
                <p>{ppcGetTouchProps.personDesignation}</p>
                <a href="tel:4152312631" className="button">
                  <span>Call Us Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </PPCGetInTouchStyle>
    )
  }
}

export default PPCGetInTouch
