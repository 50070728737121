/* methods */
// const isNodeList = nodes =>
//   NodeList.prototype.isPrototypeOf(nodes) ||
//   HTMLCollection.prototype.isPrototypeOf(nodes)

const pixelToNumber = value => parseInt(value.replace(/px/, ''), 10) || 0

const addCss = (selector, styles = {}) => {
  let tag = selector
  if (typeof selector === 'string') {
    tag = document.querySelectorAll(selector)
  }
  tag.forEach(elem => {
    for (let s in styles) {
      elem.style[s] = styles[s]
    }
  })
}

const getOuterWidth = (selector, margin = false) => {
  let element = selector
  if (typeof element === 'string') {
    element = document.querySelector(selector)
  }
  let styles = window.getComputedStyle(element)
  let totalWidth = pixelToNumber(styles.getPropertyValue('width'))
  if (margin) {
    totalWidth += pixelToNumber(styles.getPropertyValue('margin-left'))
    totalWidth += pixelToNumber(styles.getPropertyValue('margin-right'))
  }
  return totalWidth
}

const wrap = (selector, tag, options = {}) => {
  document.querySelectorAll(selector).forEach(elem => {
    let parentTag = document.createElement(tag, options)
    elem.parentElement.insertBefore(parentTag, elem)
    parentTag.appendChild(elem)
    for (let o in options) {
      parentTag[o] = options[o]
    }
  })
}
/* methods */

export const infiniteslide = (selector, options) => {
  //options
  const settings = {
    speed: 100,
    direction: 'left',
    pauseonhover: true,
    responsive: false,
    clone: 1,
    ...options,
  }

  const setCss = () => {
    wrap(selector, 'div', {
      className: 'infiniteslide_wrap',
    })

    addCss('.infiniteslide_wrap', {
      overflow: 'hidden',
    })

    let d = 'row'

    addCss(selector, {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: d,
    })

    // let childNodes = [...document.querySelector(selector).children]
    let childNodes = Array.from(document.querySelector(selector).children)
    childNodes.forEach(node => {
      addCss([node], {
        flex: '0 0 auto',
        display: 'block',
      })
    })
  }

  const setClone = clone => {
    let parent = document.querySelector(selector)
    // let nodes = [...parent.children]
    let nodes = Array.from(parent.children)
    let i = 1

    while (i <= clone) {
      nodes.forEach(node => {
        let clonedNode = node.cloneNode(true)
        clonedNode.classList.add('infiniteslide_clone')
        parent.appendChild(clonedNode)
      })
      i++
    }
  }

  const getWidth = () => {
    let w = 0

    // let children = [...document.querySelector(selector).children]
    let children = Array.from(document.querySelector(selector).children)
    children = children.filter(child =>
      child.matches(':not(.infiniteslide_clone)')
    )

    children.forEach(child => {
      w += getOuterWidth(child, true)
    })

    return w
  }

  const getSpeed = (l, s) => {
    return l / s
  }

  const getNum = () => {
    let num = getWidth()
    return num
  }

  const getTranslate = num => {
    let i = `-${num}px,0,0`
    return i
  }

  const setAnim = (id, direction, speed) => {
    let element = document.querySelector(selector)
    let num = getNum()
    let i = getTranslate(num)

    element.setAttribute('data-style', `infiniteslide${id}`)

    let css = `
    @keyframes infiniteslide${id} {
      from { transform: translate3d(0,0,0); }
      to { transform: translate3d(${i}); }
    }
    `

    let style = document.createElement('style')
    style.setAttribute('id', `infiniteslide${id}_style`)
    style.innerHTML = css
    document.head.appendChild(style)

    let reverse = ''
    if (direction === 'right') {
      reverse = ' reverse'
    }

    window.id = id

    let animation = `infiniteslide${id} ${getSpeed(
      num,
      speed
    )}s linear 0s infinite${reverse}`
    addCss(selector, {
      animation,
    })
    return animation
  }

  const setStop = () => {
    let element = document.querySelector(selector)

    element.addEventListener('mouseenter', function() {
      addCss([this], {
        animationPlayState: 'paused',
      })
    })
    element.addEventListener('mouseleave', function() {
      addCss([this], {
        animationPlayState: 'running',
      })
    })
  }

  const setResponsive = () => {
    let num = getNum()
    let i = getTranslate(num)
    return i
  }

  let element = document.querySelector(selector)
  var num = Date.now() + Math.floor(10000 * Math.random()).toString(16)
  if (settings.pauseonhover === true) {
    setStop()
  }
  setCss()
  setClone(settings.clone)
  setAnim(num, settings.direction, settings.speed)

  if (settings.responsive) {
    window.addEventListener('resize', function() {
      let i = setResponsive()
      let styleid = element.getAttribute('data-style')
      let styleTag = document.querySelector(`#${styleid}_style`)
      let stylehtml = styleTag.innerHTML

      let stylehtml_new = stylehtml.replace(
        /to {transform:translate3d\((.*?)\)/,
        `to {transform:translate3d(${i})`
      )
      styleTag.innerHTML = stylehtml_new
    })
  }

  return {
    setAnim,
    num,
  }
}
