import styled from 'styled-components'
import { lg, xl, sm, md, sh } from 'config/variables'

export const FeatureResourcesStyle = styled.section`
  background-image: linear-gradient(to bottom,rgba(242,220,204,0),#f8f9ff);
  overflow: hidden;
  margin-bottom: 0;
  .container{
    ${md(`
      padding: 0;
    `)}
    .heading-wrapper{
      max-width:100%;
      ${sm(`
        padding: 0 20px;
      `)}
    }
    .feature-listing {
      padding: 40px 0 100px;
      display: flex;
      justify-content: space-between;
      ${sh(`
        padding: 36px 0 90px;
      `)}
      ${xl(`
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        `)}
      ${lg(`
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 40px 0 60px;
        `)}
      ${sm(`
        padding: 20px 0 35px;
        `)}
      .single-blog-card {
        ${xl(`
          margin-right: 30px;
          `)}
        ${lg(`
          margin-right: 30px;
          `)}
        ${md(`
          margin: 0;
          margin-left: 20px;
        `)}
        &:last-child{
          ${md(`
            margin-right: 20px;
          `)}
        }
      }
    }
  }
`
