import React, { Component } from 'react'
import { PPCBannerStyle } from './PPCBanner.styles'
import Form from 'components/Form/Form'
import Image from 'components/ImgOptimized'
import { images } from 'config/images'

class PPCBanner extends Component {
  render() {
    const { ppcbannerProps } = this.props.ppcbannerdata
    const formCTA = ppcbannerProps.formCTA
    const {
      data: {
        bannerImage: {
          childImageSharp: { fluid },
        },
      },
    } = this.props
    return (
      <PPCBannerStyle className="ppcbanner">
        <div className="container">
          <div className="banner-left-part">
            <div className="heading">
              <h2>{ppcbannerProps.heading}</h2>
            </div>
            <p>{ppcbannerProps.para}</p>
            <div className="img-blk">
              <Image fluid={fluid} />
            </div>
          </div>
          <div className="banner-form">
            <div className="badge">
              <img src={images.badge} alt="" />
            </div>
            <div className="form-heading">
              <h3>Request a Free Quote</h3>
              <p>
                Guaranteed Response within <br />
                One Business Day!
              </p>
            </div>
            <Form CTA={formCTA} />
          </div>
        </div>
      </PPCBannerStyle>
    )
  }
}

export default PPCBanner
