import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { SingleServiceStyle } from './SingleService.styles'
import { Link } from 'gatsby'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'

class SingleService extends Component {
  render() {
    return (
      <SingleServiceStyle
        className={`service-single ${this.props.serviceClass || ''}`}
      >
        <div className="service-img-blk">
          <LazyLoad height={50} once offset={2000}>
            <img src={this.props.serviceIcon} alt={this.props.serviceAlt} />
          </LazyLoad>
        </div>
        <div className="service-content-blk">
          <h4>
            {this.props.serviceLink ? (
              <>
                {this.props.linkType !== EXTERNAL_LINK ? (
                  <Link to={this.props.serviceLink}>
                    {this.props.serviceHeading}
                  </Link>
                ) : (
                  <a
                    href={`${isWindowDefined ? window.location.origin : ''}${
                      this.props.serviceLink
                    }`}
                  >
                    {this.props.serviceHeading}
                  </a>
                )}
              </>
            ) : (
              <>{this.props.serviceHeading}</>
            )}
          </h4>
          <p>{this.props.serviceParagraph}</p>
        </div>
      </SingleServiceStyle>
    )
  }
}

export default SingleService
