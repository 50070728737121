import React, { Component } from 'react'
import { ProcessFlowStyle } from './ProcessFlow.styles'
import Image from 'components/ImgOptimized'

class ProcessFlow extends Component {
  render() {
    // const { workingBenefitsProps } = this.props.ProcessFlowData
    const { ProcessFlowData } = this.props

    return (
      <ProcessFlowStyle className="ppcbenefit-section">
        <div className="container">
          <div className="heading-wrapper">
            <h2>{ProcessFlowData.workingBenefitsHeading}</h2>
          </div>
          {ProcessFlowData.processHeading || ProcessFlowData.processPara ? (
            <div className="processflow-headig">
              {ProcessFlowData.processHeading && (
                <h4>{ProcessFlowData.processHeading}</h4>
              )}
              {ProcessFlowData.processPara && (
                <p>{ProcessFlowData.processPara}</p>
              )}
            </div>
          ) : (
            ''
          )}
          <div className="working-benefit-list">
            {ProcessFlowData.workingBenefitsProps.map(
              (workingBenefitsProp, i) => {
                return (
                  <div
                    key={i}
                    className={`benefit-single-block ${workingBenefitsProp.workingBenefitsClass}`}
                  >
                    <div className="three-layer-block">
                      <div className="back-layer" />
                      <div className="front-layer" />
                      <div className="middle-layer">
                        {this.props.items && (
                          <Image
                            fluid={this.props.items[i]}
                            alt={workingBenefitsProp.benefitImgAlt}
                            lazyload={{
                              enabled: true,
                              once: true,
                              offset: 1000,
                              heigth: 430,
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="content-block">
                      {workingBenefitsProp.workingBenefitsTitle && (
                        <h3>{workingBenefitsProp.workingBenefitsTitle}</h3>
                      )}
                      <p>{workingBenefitsProp.workingBenefitsContent}</p>
                      {workingBenefitsProp.workingBenefitsList && (
                        <ol>
                          {workingBenefitsProp.workingBenefitsList.map(
                            (orderList, i) => {
                              return <li key={`${i}${i}`}>{orderList}</li>
                            }
                          )}
                        </ol>
                      )}
                    </div>
                    <div className="patern-block">
                      <img
                        src={workingBenefitsProp.workingBenefitsPatern}
                        alt=""
                      />
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </ProcessFlowStyle>
    )
  }
}

export default ProcessFlow
