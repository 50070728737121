import React, { useState, useRef } from 'react'
import { GetInTouchFormStyle } from './Form.styles'
import loadable from '@loadable/component'
import * as Yup from 'yup'
import { images } from 'config/images'
import {
  addPipeDrivePerson,
  addPipeDriveDeal,
  fetchKeywords,
  sendMail,
  addPipeDriveDealFile,
} from 'config/pipedrive'
import {
  getPageLink,
  getPageType,
  thankYouVideo,
  sendFormSuccessGAEvent,
  logMessage,
} from 'config/utils'
import FormSubmitVideo from 'components/LightBoxImgVideo/FormSubmitVideo'
import {
  formFields,
  formNames,
  MAX_FILE_SIZE,
} from 'components/ContactStepForm/contactFormConstants'

const FormikWrapper = loadable.lib(() => import('formik'))

const Form = ({ CTA }) => {
  let [successMessage, setSuccessMessage] = useState(false)
  const inputRef = useRef()
  const [file, setFile] = useState(null)
  const [isFileValid, setValidFile] = useState(true)

  const checkFileValidity = file => {
    if (file.size > MAX_FILE_SIZE) {
      setValidFile(false)
    } else {
      setValidFile(true)
    }
    setFile(file)
  }

  const validationSchema = () => {
    return Yup.object().shape({
      [formNames.email]: Yup.string()
        .email()
        .required(`Please enter a valid email address`),
      [formNames.name]: Yup.string().required('Name is required'),
      [formNames.phone]: Yup.string().required(),
    })
  }

  return (
    <GetInTouchFormStyle className="form">
      <FormSubmitVideo
        isOpen={successMessage}
        url={thankYouVideo}
        closeLightbox={() => setSuccessMessage(false)}
      />
      <FormikWrapper>
        {({ Formik }) => (
          <Formik
            validationSchema={validationSchema()}
            initialValues={{
              [formNames.email]: '',
              [formNames.name]: '',
              [formNames.phone]: '',
              [formNames.interestedIn]: '',
              [formNames.projectDescription]: '',
            }}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setStatus, resetForm }
            ) => {
              try {
                if (window.TriblioAnalyticsObject) {
                  const form = document.querySelector('#PPC-Form')
                  const data = new FormData(form)
                  const formData = new URLSearchParams(data).toString()
                  const env = {
                    fd: formData,
                  }
                  window.TriblioAnalyticsObject.track(40, env)
                }

                let addPerson = null
                const personObj = {
                  [formNames.name]: values.name,
                  [formNames.email]: values.email,
                  [formNames.phone]: values.phone,
                }
                addPerson = await addPipeDrivePerson(personObj)
                const dealObj = {
                  title: values[formNames.name],
                  person_id: addPerson.data.id,
                  [formFields.projectDescription]:
                    values[formNames.projectDescription],
                  [formFields.interestedIn]: values[formNames.interestedIn],
                  [formFields.pageType]: getPageType(),
                  [formFields.pageLink]: getPageLink(),
                }
                const addDeal = await addPipeDriveDeal({
                  ...dealObj,
                  ...fetchKeywords(),
                })
                setSuccessMessage(true)

                let emailFields = {
                  ...values,
                  [formNames.pageType]: getPageType(),
                  [formNames.pageLink]: getPageLink(),
                  ...fetchKeywords(),
                }
                sendMail({ emailFields })
                sendFormSuccessGAEvent()
                resetForm()

                if (file) {
                  addPipeDriveDealFile({
                    file,
                    deal_id: addDeal.data.id,
                    person_id: addPerson.data.id,
                  })
                }
              } catch (error) {
                // logMessage('cannot submit ppc page form')
                console.error('cannot submit ppc page form')
              }
            }}
          >
            {({ values, handleChange, handleSubmit, errors }) => (
              <form id="PPC-Form" onSubmit={handleSubmit}>
                <div className="input-field-row">
                  <div className="form-field-blk">
                    <input
                      className={`label-input-field ${
                        values[formNames.name].length ? 'fill' : ''
                      }`}
                      type="text"
                      onChange={handleChange}
                      value={values[formNames.name]}
                      name={formNames.name}
                      id="pname"
                    />
                    <label htmlFor={formNames.name}>Full Name *</label>
                  </div>
                </div>
                <div className="input-field-row">
                  <div className="form-field-blk">
                    <input
                      className={`label-input-field ${
                        values[formNames.email].length ? 'fill' : ''
                      }`}
                      type="email"
                      onChange={handleChange}
                      value={values[formNames.email]}
                      name={formNames.email}
                      id="pemail"
                    />
                    <label htmlFor={formNames.email}>Email *</label>
                  </div>
                </div>
                <div className="input-field-row">
                  <div className="form-field-blk">
                    <input
                      className={`label-input-field ${
                        values[formNames.phone].length ? 'fill' : ''
                      }`}
                      type="phone"
                      onChange={handleChange}
                      value={values[formNames.phone]}
                      name={formNames.phone}
                      id="pphone"
                    />
                    <label htmlFor={formNames.phone}>Phone Number *</label>
                  </div>
                </div>
                <div className="input-field-row">
                  <div className="form-field-blk textarea-row">
                    <textarea
                      name={formNames.projectDescription}
                      onChange={handleChange}
                      value={values[formNames.projectDescription]}
                      className={`label-input-field textarea ${
                        values[formNames.projectDescription].length
                          ? 'fill'
                          : ''
                      }`}
                      id="pdescription"
                    />
                    <label htmlFor={formNames.projectDescription}>
                      Tell us about your project
                    </label>
                  </div>
                  <div className="input-file-row">
                    <label
                      htmlFor="project_brief"
                      className={!isFileValid ? 'form-error' : ''}
                    >
                      <div className="file-content">
                        <div className="icon">
                          <img src={images.iconDownload} alt="" />
                        </div>
                        <div className="content-label">
                          {file ? file.name : 'Upload your project brief'}
                        </div>
                      </div>
                      <div className="row-wrap">
                        {file && (
                          <div
                            onClick={e => {
                              inputRef.current.value = ''
                              setFile(null)
                              setValidFile(true)
                            }}
                            className="btn-close"
                          ></div>
                        )}
                      </div>
                    </label>
                    <input
                      className="file-field"
                      type="file"
                      accept="application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
                      onChange={e => checkFileValidity(e.target.files[0])}
                      ref={inputRef}
                      name="project_brief"
                      id="pprojectbrief"
                    />
                    {!isFileValid && (
                      <span className="error-msg">Max file size is 10 MB</span>
                    )}
                  </div>
                </div>

                <div className="bottomAlert">
                  <div className="submit-row">
                    <button className="btn-submit" type="submit">
                      {CTA}
                    </button>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <div>
                      <div className="bottomErrorText">
                        Please fill all the required fields
                      </div>
                    </div>
                  )}
                </div>

                <div className="checkbox-row">
                  <input
                    type="checkbox"
                    name="check1"
                    id="check1"
                    defaultChecked
                  />
                  <label htmlFor="check1">
                    Please send me a Non-Disclosure Agreement for a Confidential
                    Consultation
                  </label>
                </div>
              </form>
            )}
          </Formik>
        )}
      </FormikWrapper>
    </GetInTouchFormStyle>
  )
}

export default Form
