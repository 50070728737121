import styled from 'styled-components'
import {
  sm,
  md,
  themeColor,
  headingFont,
  white,
  sh,
  primaryColor,
} from 'config/variables'

export const ClientLogoStyle = styled.div`
  width: 100%;
  .client-heading {
    max-width: 553px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    ${sh(`
      max-width: 470px;
    `)}
    h4 {
      font-size: 26px;
      line-height: 33px;
      letter-spacing: -0.64px;
      color: ${primaryColor};
      margin-bottom: 45px;
      text-align: center;
      ${sh(`
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 41px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 40px;
        `)}
      ${sm(`
        font-size: 20px;
        line-height: 28px;
        padding: 0 20px;
        margin-bottom: 35px;
        `)}
    }
  }
  .client-logo-listing-wrap {
    background: rgba(215, 233, 255, 0.15);
    padding: 20px 0;
    position: relative;
    overflow: hidden;
    ${sh(`
      padding: 18px 0;
    `)}
    ${md(`
      padding: 15px 0;
    `)}
    .client-logo-listing {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      transition: all 500ms linear;
      &:hover {
        .logo-block {
          filter: blur(3px);
        }
      }
      .logo-block {
        padding: 0 45px;
        display: flex;
        align-items: center;
        position: relative;
        filter: blur(0px);
        transition: all 500ms linear;
        ${sh(`
          padding: 0 41px;
          transform: scale(0.85);
        `)}
        &:hover {
          filter: blur(0px);
        }
        ${md(`
          padding: 0 30px;
          `)}
        ${sm(`
          padding: 0 20px;
          `)}
        img {
          transition: all 500ms linear;
          /* ${md(`
            transform: scale(0.7);
          `)} */
        }
        .hvr-logo {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          transition: all 500ms linear;
          opacity: 0;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .client-count {
      position: absolute;
      top: 50%;
      right: 0px;
      z-index: 9;
      transform: translateY(-50%);
      width: 100px;
      height: auto;
      padding: 8px 16px;
      border-radius: 3px;
      background-color: ${themeColor};
      text-align: center;
      ${sh(`
        padding: 8px 12px;
      `)}
      ${md(`
        display: none;
        `)}
      h5 {
        font-size: 30px;
        line-height: 38px;
        color: ${white};
        margin: 0;
        ${sh(`
          font-size: 28px;
          line-height: 36px;
        `)}
        ${sm(`
          font-size: 18px;
          line-height: 23px;
          `)}
      }
      span {
        font-family: ${headingFont};
        color: ${white};
        font-size: 12px;
        line-height: 15px;
        font-weight: 900;
        letter-spacing: 0px;
        display: block;
        ${sh(`
          font-size: 11px;
          line-height: 14px;
        `)}
        ${sm(`
          font-size: 12px;
          `)}
      }
    }
  }
`
