import styled from 'styled-components'
import { images } from 'config/images'
import {
  xl,
  lg,
  md,
  sm,
  themeColor,
  sh,
  headingFont,
  primaryColor,
} from 'config/variables'

export const PPCBannerStyle = styled.section`
  padding: 120px 0 131px;
  background-image: url(${images.ppcBG});
  background-position: 100% 0%;
  background-size: auto 778px;
  background-repeat: no-repeat;
  margin: 0;
  ${xl(`
    background-size: auto 608px;
    background-position: 120% 70%;
  `)}
  ${lg(`
    padding: 100px 0 131px;
    background-position: 260% 98%;
  `)}
  ${md(`
    padding: 100px 0 62px;
    background-size: auto 598px;
    background-position: 0% 97%;
  `)}
  .container {
    display: flex;
    max-width: 1120px;
    justify-content: space-between;
    align-items: flex-start;
    ${lg(`
      flex-wrap: wrap;
    `)}
    .banner-left-part {
      width: 100%;
      max-width: 560px;
      text-align: left;
      padding-right: 20px;
      ${lg(`
        max-width: 100%;
        margin-bottom: 65px;
      `)}
      ${sm(`
        padding-right: 0;
        margin-bottom: 40px;
      `)}
      p {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
        ${sh(`
          font-size: 20px;
          line-height: 28px;
        `)}
        ${md(`
          font-size: 20px;
          line-height: 28px;
        `)}
        ${sm(`
          font-size: 18px;
          line-height: 28px;
        `)}
      }
      .img-blk {
        width: 100%;
        display: block;
        margin-top: 50px;
        img {
          display: block;
        }
      }
    }
    .banner-form {
      width: 100%;
      max-width: 390px;
      box-shadow: 3px 0 10px -2px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      background: #fff;
      padding: 25px 24px;
      position: relative;
      ${sh(`
        max-width: 350px;
      `)}
      ${lg(`
        max-width: 100%;
      `)}
      .badge{
        position: absolute;
        top: -20px;
        right: -46px;
        ${sm(`
          width: 65px;
          right: -16px;
        `)}
      }
      .form-heading{
        text-align: center;
        padding: 0 22px;
        ${sm(`
          padding: 0 10px;
        `)}
        h3{
          font-size: 20px;
          color: ${primaryColor};
          line-height: 25px;
          margin-bottom: 2px;
        }
        p{
          font-size: 15px;
          line-height: 21px;
          color: ${primaryColor};
          margin-bottom: 29px;
          span{
            color: ${themeColor};
            font-weight: 500;
          }
          br{
            ${sm(`
              display: none;
            `)}
          }
        }
      }
      .form {
        padding: 0px;
        ${lg(`
          max-width: 100%;
        `)}
        .bottomAlert{
          .submit-row{
            margin-top: 0;
          }
        }
      }
      .mobile-btn-row{
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        .btn-call{
          font-size: 14px;
          font-family: ${headingFont};
          line-height: 22px;
          letter-spacing: 1.4px;
          color: ${themeColor};
          font-weight: 600;
          position: relative;
          padding-left: 19px;
          text-transform: uppercase;
          &:before{
            content: '';
            position: absolute;
            width: 17px;
            height: 22px;
            top: -2px;
            left: -3px;
            background: url(${images.iconCallRed})
          }
        }
      }
    }
  }
`
